header {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

header > .topbar {
    min-height: 50px;
    border-bottom: 1px solid var(--darkGray);
    font-size: 30px;
    color: var(--white);
    margin-bottom: auto;
}
