.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    z-index: 100;
    background-color: #000000d0;
    display: flex;
    transition: opacity 0.5s;
}

.overlay.visible.wait,
.overlay.visible.wait .overlay-contents {
    transition-delay: 1.5s;
}

.overlay:not(.visible) {
    opacity: 0;
    pointer-events: none;
}

.overlay:not(.visible) .overlay-contents {
    transform: translateY(calc(-50vh - 50%));
}

.overlay-contents {
    margin: auto;
    background-color: var(--black);
    width: 500px;
    max-width: 100%;
    min-height: 100px;
    border: 1px solid var(--darkGray);
    box-shadow: 0 0 20px var(--black);
    border-radius: 5px;
    transition: transform 0.5s;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.overlay .title {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
}

.overlay .title.won {
    color: #00a000;
}

.overlay .title.lost {
    color: #c01010;
}

.ovelray .results-wrapper {
    padding: 10px;
}

.overlay .results {
    max-width: 350px;
}

.overlay p {
    font-size: 25px;
    margin: 10px;
    color: var(--white);
}

.overlay .results-wrapper {
    margin: 20px;
}

.overlay .btn {
    height: 50px;
    border-radius: 5px;
    border: none;
    flex-grow: 1;
    color: var(--white);
    font-weight: bold;
    font-size: 25px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: Trebuchet MS, sans-serif;
}

.overlay .btn-close {
    background-color: #606060;
}
.overlay .btn-close:hover {
    background-color: #404040;
}

.overlay .btn-share {
    background-color: #008000;
}
.overlay .btn-share:hover {
    background-color: #006000;
}

.overlay .buttons hr {
    border: none;
    width: 10px;
}
